import "core-js/stable";
import Vue from "vue";
import App from "./App";
import router from "./router";
import CoreuiVue from "@coreui/vue";
import { iconsSet as icons } from "./assets/icons/icons.js";
import store from "./store";
import VueCookies from "vue-cookies";
import configs from "./configs";
import configs_accred from "./configs_accred";
import configs_ruvents_dev from "./configs_ruvents_dev";
import configs_crki_art from "./configs_crki_art";
import configs_accred_ruvents_dev from "./configs_accred_ruvents_dev";

const vueConfig = require("vue-config");
import YmapPlugin from "vue-yandex-maps";

Vue.use(VueCookies);

// set default config
Vue.$cookies.config("60d");

Vue.config.performance = true;
Vue.use(CoreuiVue);
Vue.prototype.$log = console.log.bind(console);

const settings = {
  apiKey: "9ca13dec-13dd-43a3-83d5-280928e70e7f",
  lang: "ru_RU",
  coordorder: "latlong",
  enterprise: false,
  version: "2.1",
};

Vue.use(YmapPlugin, settings);

if (window.location.hostname == "admin.accred.ru") {
  Vue.use(vueConfig, configs_accred);
} else if (window.location.hostname == "admin.ruvents.dev") {
  Vue.use(vueConfig, configs_ruvents_dev);
} else if (window.location.hostname == "admin.crki.art") {
  Vue.use(vueConfig, configs_crki_art);
} else if (window.location.hostname == "admin.accred.ruvents.dev") {
  Vue.use(vueConfig, configs_accred_ruvents_dev);
} else {
  Vue.use(vueConfig, configs);
}

new Vue({
  el: "#app",
  router,
  store,
  icons,
  template: "<App/>",
  components: {
    App,
  },
});
