import Vue from "vue";
import Router from "vue-router";

// Containers
const TheContainer = () => import("@/containers/TheContainer");

const Admin = () => import("@/views/Admin");
const Login = () => import("@/views/Login");
const Dashboard = () => import("@/views/Dashboard");
const Event = () => import("@/views/Event");
const ArchivedEvent = () => import("@/views/ArchivedEvent");
const Status = () => import("@/views/Status");
const Contact = () => import("@/views/Contact");
const Attribute = () => import("@/views/Attribute");
const CreateEvent = () => import("@/views/CreateEvent");
const DataSource = () => import("@/views/DataSource");
const DataSourceCfg = () => import("@/views/DataSourceCfg");
const GlobalAction = () => import("@/views/GlobalAction");
const OperatorAction = () => import("@/views/OperatorAction");
const EventAction = () => import("@/views/EventAction");
const DeviceAction = () => import("@/views/DeviceAction");
const Device = () => import("@/views/Device");
const ChangePassword = () => import("@/views/ChangePassword");

Vue.use(Router);

export default new Router({
  mode: "hash", // https://router.vuejs.org/api/#mode
  linkActiveClass: "active",
  scrollBehavior: () => ({ y: 0 }),
  routes: configRoutes(),
});

function configRoutes() {
  return [
    {
      path: "/",
      redirect: "/dashboard",
      name: "Главная",
      component: TheContainer,
      children: [
        {
          path: "dashboard",
          name: "Главная",
          component: Dashboard,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "admin",
          name: "Пользователи",
          component: Admin,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "login",
          name: "Вход",
          component: Login,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "event",
          name: "Мероприятия",
          component: Event,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "archivedevent",
          name: "Архив Мероприятий",
          component: ArchivedEvent,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "addevent",
          name: "Создать мероприятие",
          component: CreateEvent,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "editevent/:id",
          name: "Редактировать мероприятие",
          component: CreateEvent,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "status",
          name: "Статусы",
          component: Status,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "contact",
          name: "Контакты",
          component: Contact,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "attribute",
          name: "Справочник атрибутов",
          component: Attribute,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "datasource",
          name: "Источники данных",
          component: DataSource,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "datasourcecfg/:id",
          name: "Конфигурация источников данных на мерприятии",
          component: DataSourceCfg,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "globalaction",
          name: "Глобальный лог",
          component: GlobalAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "operatoraction/:id",
          name: "Лог Оператора",
          component: OperatorAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "eventaction/:id",
          name: "Лог Мероприятия",
          component: EventAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "deviceaction/:id",
          name: "Лог Устройства",
          component: DeviceAction,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "device",
          name: "Устройства",
          component: Device,
          props: (route) => ({ token: route.query.token }),
        },
        {
          path: "changepassword",
          name: "Сменить пароль",
          component: ChangePassword,
          props: (route) => ({ token: route.query.token }),
        },
      ],
    },
  ];
}
